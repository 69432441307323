import React, { useState, useEffect, useReducer } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        loaded: false,
        isLoading: true,
        isError: false,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        loaded: true,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        loaded: false,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
};

function useDataApi(initialRequest, initialData, onError, onSuccess) {
  const [request, setRequest] = useState(initialRequest);
  const [t] = useTranslation();
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    loaded: false,
    data: initialData,
  });

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: FETCH_INIT });
      try {
        const result = await request;

        if (!didCancel) {
          dispatch({
            type: FETCH_SUCCESS,
            payload: result && result.data ? result.data : null,
          });
          if (onSuccess) {
            return result && result.data ? onSuccess(result.data) : onSuccess();
          }
        }
      } catch (error) {
        if (!didCancel) {
          let errorMessage = 'Server error';
          if (error.code) {
            switch (error.code) {
              case 'auth/wrong-password':
                errorMessage = t('loginForm.wrong-password');
                break;
              case 'auth/invalid-email':
                errorMessage = t('loginForm.invalid-email');
                break;
              case 'auth/user-not-found':
                errorMessage = t('loginForm.user-not-found');
                break;
              case 'auth/email-already-in-use':
                errorMessage = t('loginForm.email-already-in-use');
                break;
              case 'auth/user-disabled':
                errorMessage = t('loginForm.user-disabled');
                break;
              case 'auth/weak-password':
                errorMessage = t('loginForm.weak-password');
                break;
              case 'auth/requires-recent-login':
                errorMessage = t('loginForm.requires-recent-login');
                break;
              case 'auth/credential-already-in-use':
                errorMessage = t('loginForm.credential-already-in-use');
                break;
              case 'auth/user-token-expired':
                errorMessage = t('loginForm.user-token-expired');
                break;
              case 'auth/invalid-user-token':
                errorMessage = t('loginForm.invalid-user-token');
                break;
              case 'auth/network-request-failed':
                errorMessage = t('loginForm.network-request-failed');
                break;
              case 'auth/captcha-check-failed':
                errorMessage = t('loginForm.captcha-check-failed');
                break;
              case 'auth/invalid-phone-number':
                errorMessage = t('loginForm.invalid-phone-number');
                break;
              case 'auth/missing-phone-number':
                errorMessage = t('loginForm.missing-phone-number');
                break;
              default:
                errorMessage = `Error: ${error.message}`;
            }
          }
          if (onError) {
            onError(errorMessage);
          }
          return dispatch({ type: FETCH_FAILURE });
        }
      }
      return didCancel;
    };

    if (typeof request !== 'boolean') {
      fetchData();
    }

    return () => {
      didCancel = true;
    };
  }, [request]);

  return [state, setRequest];
}

export { useDataApi };
