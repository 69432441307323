import React, { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRecoilValueLoadable } from 'recoil';
import AmberPrimaryButton from '../../buttons/AmberPrimaryButton';
import AmberDialog from '../dialogs/AmberDialog';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import AmberHeadingBold from '../../labels/AmberHeadingBold';
import AmberOutlinedButton from '../../buttons/AmberOutlinedButton';
import AmberDialogActions from '../dialogs/AmberDialogActions';
import {
  exportHighQualityVideo,
  exportTranscriberLatestSRT,
} from '../../../utils/api';
import Notes from '../notes';
import AmberTextButton from '../../buttons/AmberTextButton';
import { AMBER_PRIMARY } from '../../../constants/colours';
import { getExportOptionsSelectorFamily } from '../../../store/jobManagerDashboard';

const useStyles = makeStyles(() => ({
  linkItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
  },
  actionItems: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  dialogPaper: {
    minHeight: '50%',
    maxHeight: '85%',
  },
  outlineBtn: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

function escapeNotes(notes) {
  if (!notes) return '';
  notes = notes.replace(/^"|"$/g, '');

  return notes;
}
function JobGuidnessDialog(props) {
  const [t] = useTranslation();
  const classes = useStyles();

  const { open, handleClose, job } = props;

  const openLink = (link) => {
    window.open(link, '_blank');
  };
  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(job?.jobId),
  );

  const shouldShowUploadCaptionsBtn = (uploadOptions) =>
    !!uploadOptions?.length;

  const openFile = async (link) => {
    switch (link) {
      case 'hd':
        exportHighQualityVideo(job.recordId);
        break;
      case 'srt':
        await exportTranscriberLatestSRT(job.sourceJobId);
        break;
      default:
        if (link) {
          window.open(link, '_blank');
        }
        break;
    }
  };

  const supportingFiles = job?.supportingFiles || [];
  return (
    <AmberDialog
      open={open}
      title={t('dialogGuidness.title')}
      fullWidth
      handleClose={handleClose}
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent>
        {job.notes && (
          <>
            {' '}
            <AmberHeadingBold
              overflow
              testid="job-card-title"
              text={t('dialogGuidness.notes')}
            />
            <Notes
              notes={escapeNotes(job.notes)}
              canEditNotes={false}
              isRequestLoading={false}
              isReadonly={true}
            />
          </>
        )}
        {supportingFiles && supportingFiles.length ? (
          <div style={{ marginBottom: 15 }}>
            <AmberHeadingBold
              overflow
              testid="job-card-title"
              text={t('dialogGuidness.supportingFiles')}
            />
            {supportingFiles?.map((file) => (
              <div>
                <AmberTextButton
                  text={'- ' + file.description}
                  onClick={() => openLink(file.url)}
                  color={AMBER_PRIMARY}
                  textProps={{
                    padding: 0,
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          ''
        )}

        <AmberHeadingBold
          overflow
          testid="job-card-title"
          text={t('dialogGuidness.externalFiles')}
        />

        <AmberDialogActions classes={{ root: classes.actionItems }}>
          <AmberOutlinedButton
            text={t('dialogGuidness.HDFile')}
            classes={{ root: classes.outlineBtn }}
            onClick={() => openFile('hd')}
          />
          {job?.sourceJobId && shouldShowUploadCaptionsBtn(
            exportOptions?.contents?.uploadOptions,
          ) && (
            <AmberOutlinedButton
              text={t('dialogGuidness.SRTFile')}
              classes={{ root: classes.outlineBtn }}
              onClick={() => openFile('srt')}
            />
          )}
          <AmberOutlinedButton
            text={t('dialogGuidness.OOONASettingsPreset')}
            classes={{ root: classes.outlineBtn }}
            onClick={() => openFile(job?.editorConfig?.ooonaSettingsPreset)}
          />

          <AmberOutlinedButton
            text={t('dialogGuidness.OOONAImportFile')}
            classes={{ root: classes.outlineBtn }}
            onClick={() => openFile(job?.editorConfig?.ooonaImportFile)}
          />
          <AmberOutlinedButton
            text={t('dialogGuidness.OOONAExportFile')}
            classes={{ root: classes.outlineBtn }}
            onClick={() => openFile(job?.editorConfig?.ooonaExportFile)}
          />
        </AmberDialogActions>

        <AmberDialogActions classes={{ root: classes.linkItems }}>
          <AmberPrimaryButton
            text={t('dialogGuidness.openInExternalEditor')}
            onClick={() => openLink(job?.editorConfig?.editorLink)}
            disabled={false}
          />
        </AmberDialogActions>
      </DialogContent>
    </AmberDialog>
  );
}
JobGuidnessDialog.propTypes = {
  handleOnClickAction: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  job: PropTypes.shape({ status: PropTypes.string }).isRequired,
};

export default JobGuidnessDialog;
