import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  useRecoilCallback,
  useRecoilValue,
  useRecoilValueLoadable,
} from 'recoil';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import {
  reportQCJob,
  submitCaptionsHQReview,
  submitStandardReview,
  submitTranslatedSubtitlesReview,
  uploadCaptions,
  uploadDocument,
} from '../../../../utils/api';
import AmberSectionTitle from '../../../labels/AmberSectionTitle';
import withQualityControlLoading from '../../../../containers/withQualityControlLoading';
import useSnackbar from '../../../../utils/useSnackbar';
import JobRatingDialog from '../../../common/job_rating_dialog/jobRatingDialog';
import {
  RATING_PERFECT_DONE_STATE,
  RATING_PERFECT_REVIEW_NOT_PASSED_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
  WORKFLOW_STYLES,
} from '../../../../constants/constants';
import {
  activeJobsAtom,
  completedJobsAtom,
  getNumberOfActiveJobsSelector,
} from '../../../../store/qualityControl';

import JobEmptyState from '../jobEmptyState/jobEmptyState';
import {
  PERFECT_DONE,
  PERFECT_REVIEW_NOT_PASSED,
} from '../../../../constants/jobStatuses';
import { EDITOR_LINK } from '../../../../settings';
import {
  CAPTIONS,
  TRANSLATED_SUBTITLES,
} from '../../../../constants/transcriptionTypes';
import JobWrapper from './jobOptions';

const useStyles = makeStyles((theme) => ({
  activeJobsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '2em',
  },
  activeJobTitle: {
    color: theme.palette.custom.darkGreenColor,
    fontWeight: 'bolder',
  },
}));

const setJobStatus = (jobToUpdate, status) => {
  const job = jobToUpdate;
  switch (status) {
    case RATING_PERFECT_DONE_STATE:
      job.status = PERFECT_DONE;
      break;
    case RATING_PERFECT_REVIEW_NOT_PASSED_STATE:
      job.status = PERFECT_REVIEW_NOT_PASSED;
      break;
    default:
      break;
  }
};

const filterJobs = (jobs, jobId) => jobs.filter((job) => job._id !== jobId);

const handleCaptionsReviewSubmission = async (workflowStyle, data) => {
  const { _id, notes, ratings, status } = data;
  switch (workflowStyle) {
    case WORKFLOW_STYLES.HQ:
      await submitCaptionsHQReview(_id, notes, ratings, status);
      break;
    default:
      await submitStandardReview(_id, notes, ratings, status);
      break;
  }
};

const submitReview = async (transcriptionType, workflowStyle, data) => {
  const { _id, notes, ratings, status } = data;
  switch (transcriptionType) {
    case TRANSLATED_SUBTITLES:
      await submitTranslatedSubtitlesReview(_id, notes, ratings, status);
      break;
    case CAPTIONS:
      await handleCaptionsReviewSubmission(workflowStyle, data);
      break;
    default:
      await submitStandardReview(_id, notes, ratings, status);
      break;
  }
};

const uploadSubtitles = async (
  files,
  resourceIdentifier,
  transcriptionType,
) => {
  if (files && files.length) {
    const uploadPromises = files.map((fileToUpload) => {
      const fileExtension = fileToUpload.name.split('.').pop();
      return TRANSCRIPTION_TYPE_TRANSCRIPTION === transcriptionType
        ? uploadDocument(resourceIdentifier, fileToUpload)
        : uploadCaptions(resourceIdentifier, fileToUpload, fileExtension);
    });
    await Promise.all(uploadPromises);
    return;
  }
  throw new Error('You cannot submit jobs without uploading any files');
};

function ActiveJobsList(props) {
  const { setIsLoading, isLoading, setUseBackdrop } = props;
  const [t] = useTranslation();
  const { showError } = useSnackbar();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [currentOpenJob, setCurrentOpenJob] = useState({});

  const activeJobs = useRecoilValueLoadable(activeJobsAtom);
  const completedJobs = useRecoilValueLoadable(completedJobsAtom);

  const activeJobsNumber = useRecoilValue(getNumberOfActiveJobsSelector);
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(
      activeJobs.state === RECOIL_LOADABLE_LOADING_STATE ||
        completedJobs.state === RECOIL_LOADABLE_LOADING_STATE,
    );
  }, [activeJobs]);

  const handleJobReported = useRecoilCallback(
    ({ set }) =>
      async (jobId, issueType, note) => {
        setIsLoading(true);
        try {
          await reportQCJob(jobId, issueType, note);
          const newActiveList = filterJobs(activeJobs.contents, jobId);
          set(activeJobsAtom, [...newActiveList]);
        } catch (error) {
          showError(error.message);
        } finally {
          setIsLoading(false);
        }
      },
  );

  const handleRedirectToEditor = useCallback((jobId) => {
    const toEditorLink = `${EDITOR_LINK}/${jobId}?workMode=perfectPlatform`;
    const editorWindow = window.open(toEditorLink, '_blank');
    editorWindow.focus();
  });

  const handleOpenReviewDialog = useCallback((jobId) => {
    setOpenReviewDialog(true);
    const foundJob = activeJobs.contents.find((job) => job._id === jobId);
    setCurrentOpenJob(foundJob);
  });

  const handleUploadSrtAndReview = useRecoilCallback(
    ({ set }) =>
      async (status, data) => {
        const { ratings, notes, files } = data;
        setIsLoading(true);
        setUseBackdrop(false);
        try {
          const modifiedJob = { ...currentOpenJob };
          const { _id, workflowStyle, transcriptionType } = modifiedJob;
          setJobStatus(modifiedJob, status);
          if (status !== RATING_PERFECT_REVIEW_NOT_PASSED_STATE) {
            await uploadSubtitles(files, _id, transcriptionType);
          }

          await submitReview(transcriptionType, workflowStyle, {
            _id,
            notes,
            ratings,
            status,
          });

          const newCompletedList = [...completedJobs.contents];
          newCompletedList.push(modifiedJob);
          set(completedJobsAtom, [...newCompletedList]);

          const newActiveList = filterJobs(activeJobs.contents, _id);
          set(activeJobsAtom, [...newActiveList]);
        } catch (error) {
          showError(error.message);
        } finally {
          setUseBackdrop(true);
          setIsLoading(false);
          setOpenReviewDialog(false);
          setCurrentOpenJob({});
        }
      },
  );

  const handleCloseDialog = useCallback(() => {
    setOpenReviewDialog(false);
  }, [setOpenReviewDialog]);

  return (
    <div className={classes.activeJobsContainer}>
      <AmberSectionTitle
        classes={{ root: classes.activeJobTitle }}
        typographyVariant="body2"
        text={t('qualityControlDashboard.activeJobsTitle')}
      />
      {!activeJobsNumber && (
        <JobEmptyState
          stringBold={t('qualityControlDashboard.noActiveJobs')}
          stringSecondary={t('qualityControlDashboard.checkJobsBelow')}
        />
      )}
      {!!activeJobsNumber &&
        activeJobs.contents.map((activeJob) => (
          <JobWrapper
            job={activeJob}
            handleOpenReviewDialog={handleOpenReviewDialog}
            handleRedirectToEditor={handleRedirectToEditor}
            handleOnClickReport={handleJobReported}
          />
        ))}
      <JobRatingDialog
        open={openReviewDialog}
        handleOnClickAction={handleUploadSrtAndReview}
        handleClose={handleCloseDialog}
        job={currentOpenJob}
        loadingActionRequest={isLoading}
      />
    </div>
  );
}

ActiveJobsList.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  setUseBackdrop: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default withQualityControlLoading(ActiveJobsList);
