import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';
import JobsTable from '../components/job/JobsTable';
import {
  assign,
  assignPJMToJob,
  assignQualityControllers,
  cancelJobRequest,
  getFiltersOptions,
  getJobs,
  invite,
  notifyClient,
  resetJobStatus,
  searchFileNames,
  setQualityController,
  setSkipQualityControl,
  updateClientDeadline,
  updateQualityControllerDeadline,
  updateTranscriberDeadline,
} from '../utils/api';
import { useSelectMultiple } from '../utils/useSelectHooks';
import { useDataApi } from '../utils/apiHooks';
import useSnackbar from '../utils/useSnackbar';
import JobDetailsContainer from './jobDetails/JobDetailsContainer';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '80px',
    marginBottom: 40,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const initFilterOptions = {
  perfectJobManagers: [],
  transcribers: [],
  qualityControllers: [],
  languages: [],
};

function JobManagerDashboard(props) {
  const classes = useStyles();
  const { tabValue } = props;
  const { showError, showSuccess } = useSnackbar();
  const [refreshTableCount, setRefreshTableCount] = useState(0);

  const [selected, handleSelectItem] = useSelectMultiple([]);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const [currentJobId, setCurrentJobId] = React.useState({
    jobId: null,
    openNoteTab: false,
  });
  const [currentGlossaryId, setCurrentGlossaryId] = React.useState(null);

  function onError(msg) {
    showError(msg);
  }

  function refreshTable() {
    setRefreshTableCount(() => refreshTableCount + 1);
  }

  const [jobs, doFetchJobs] = useDataApi(
    false,
    { content: {}, totalElements: 0 },
    onError,
  );
  const [filterOptions, doFetchFilterOptions] = useDataApi(
    false,
    initFilterOptions,
    onError,
  );

  const [, doInviteTranscribers] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    handleSelectItem(null);
    setSelectedJobs([]);
    refreshTable();
  });
  const [, doAssignTranscribers] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    handleSelectItem(null);
    setSelectedJobs([]);
    refreshTable();
  });
  const [, doAssignQualityControllers] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    handleSelectItem(null);
    setSelectedJobs([]);
    refreshTable();
  });
  const [, doAssignManagers] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshTable();
  });
  // Reopen job
  const [, doReopenJob] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    handleSelectItem(null);
    setSelectedJobs([]);
    refreshTable();
  });
  // Cancel job
  const [, doCancelJob] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    handleSelectItem(null);
    setSelectedJobs([]);
    refreshTable();
  });
  // Change transcriberDeadline
  const [, doChangeTranscriberDeadline] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshTable();
  });
  // Change clientDeadline
  const [, doChangeClientDeadline] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshTable();
  });
  const [, doChangeQualityControllerDeadline] = useDataApi(
    false,
    '',
    onError,
    () => {
      showSuccess('Success');
      refreshTable();
    },
  );
  const [, doSetQualityController] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshTable();
  });

  const [, doSetSkipQualityControl] = useDataApi(false, '', onError, () => {
    showSuccess('Success');
    refreshTable();
  });

  function loadJobList(options) {
    doFetchJobs(getJobs(options));
  }

  function loadFiltersOptions() {
    doFetchFilterOptions(getFiltersOptions());
  }

  async function onChangeFileNameFilter(keyword, callback) {
    try {
      const result = await searchFileNames(keyword);
      if (result.status === 200) {
        callback(result.data);
      }
    } catch (error) {
      showError(error.response.data.message);
    }
  }

  // Invite transcriber to a job
  function handleInvite(transcribers, jobIds) {
    doInviteTranscribers(invite(transcribers, jobIds));
  }

  // Assign jobs to transcribers
  async function handleAssign(transcribers, jobIds) {
    doAssignTranscribers(assign(transcribers, jobIds));
  }

  async function handleAssignQualityControllers(qualityController, jobIds) {
    doAssignQualityControllers(
      assignQualityControllers(qualityController, jobIds),
    );
  }

  // Assign jobs to managers
  async function handleAssignManagers(jobIds, pjm) {
    doAssignManagers(assignPJMToJob(jobIds, pjm));
  }

  // Reopen a job (reset the status)
  async function handleReopenJobs(jobId) {
    doReopenJob(resetJobStatus(jobId));
  }

  // Cancel a job
  async function handleCancelJob(jobId) {
    doCancelJob(cancelJobRequest(jobId));
  }

  // Change transcriberDeadline of a job
  async function handleTranscriberDeadline(jobId, transcriberDeadline) {
    const jobsToUpdate = Array.isArray(jobId) ? jobId : [jobId];
    doChangeTranscriberDeadline(
      updateTranscriberDeadline(jobsToUpdate, transcriberDeadline),
    );
  }

  async function handleQualityControllerDeadline(
    jobId,
    qualityControllerDeadline,
  ) {
    const jobsToUpdate = Array.isArray(jobId) ? jobId : [jobId];
    doChangeQualityControllerDeadline(
      updateQualityControllerDeadline(jobsToUpdate, qualityControllerDeadline),
    );
  }

  async function handleSetQualityController(jobId, qualityControllerDeadline) {
    doSetQualityController(
      setQualityController(jobId, qualityControllerDeadline),
    );
  }

  async function handleSetSkipQualityControl(jobId, qualityControlSkip) {
    doSetSkipQualityControl(setSkipQualityControl(jobId, !qualityControlSkip));
  }

  // Change transcriberDeadline of a job
  async function handleClientDeadline(jobId, clientDeadline, notify) {
    const jobsToUpdate = Array.isArray(jobId) ? jobId : [jobId];
    try {
      const response = await updateClientDeadline(jobsToUpdate, clientDeadline);
     
      if (response.isError) {
        // handle error response
        showError(response.message);
        return;
      }
      if (notify) {
        await Promise.all(jobsToUpdate.map((id) => notifyClient(id)));
      }
      doChangeClientDeadline(response);
    } catch (error) {
      if (error.response) {
        // handle HTTP error
        const errorMessage = error.response.data.message;
        showError(errorMessage);
      } else {
        // handle unexpected error
        showError('An unexpected error occurred');
      }
    }
  }

  // Gets the glossaryId from a job with a specific jobId
  function getGlossaryFromSelectedJob(jobId) {
    if (jobs && jobs.data && jobs.data.content) {
      const { content } = jobs.data;
      const selectedJob = content.find((job) => job.jobId === jobId);
      return selectedJob.glossaryId;
    }
    return null;
  }

  function showJobDetails(jobId, openDialog) {
    setCurrentJobId({
      jobId,
      openDialog,
    });
    setCurrentGlossaryId(getGlossaryFromSelectedJob(jobId));
  }

  function closeJobDetailsHandler() {
    setCurrentJobId({
      jobId: null,
      openDialog: false,
    });
    setCurrentGlossaryId(null);
  }

  return (
    <div className={classes.container}>
      <JobsTable
        handleClientDeadline={handleClientDeadline}
        handleTranscriberDeadline={handleTranscriberDeadline}
        handleQualityControllerDeadline={handleQualityControllerDeadline}
        handleReopenJobs={handleReopenJobs}
        handleSetSkipQualityControl={handleSetSkipQualityControl}
        handleSetQualityController={handleSetQualityController}
        handleCancelJob={handleCancelJob}
        handleAssignQualityController={handleAssignQualityControllers}
        jobs={jobs}
        filterOptions={filterOptions}
        loadJobList={loadJobList}
        loadFiltersOptions={loadFiltersOptions}
        onChangeFileNameFilter={onChangeFileNameFilter}
        handleInvite={handleInvite}
        handleAssign={handleAssign}
        handleAssignManagers={handleAssignManagers}
        showDetails={showJobDetails}
        refreshTable={refreshTableCount}
        handleSelectItem={handleSelectItem}
        selected={selected}
        selectedJobs={selectedJobs}
        setSelectedJobs={setSelectedJobs}
        refreshJobTable={refreshTable}
      />

      {!!currentJobId.jobId && (
        <JobDetailsContainer
          jobId={currentJobId.jobId}
          openDialog={currentJobId.openDialog}
          closeJobDetailsHandler={closeJobDetailsHandler}
          refreshJobsTable={refreshTable}
          tabValue={tabValue}
          glossaryId={currentGlossaryId}
        />
      )}
    </div>
  );
}

export default JobManagerDashboard;
JobManagerDashboard.propTypes = {
  tabValue: PropTypes.number,
};

JobManagerDashboard.defaultProps = {
  tabValue: 0,
};
