import axios from 'axios';
import { saveAs } from 'file-saver';
import apiClient from './apiClient';
import { logout as postLogoutProcess } from './auth';
import {
  baseURL,
  clientId,
  clientSecret,
  headers,
  storeUserDetailsToLocalStorage,
  wordExportEndpoint,
} from './apiHelper';

// ******************** REQUESTS ******************** \\
/* eslint-disable */
export function login(email, password) {
  const url = `${baseURL}token`;
  const data = {
    username: email,
    password,
  };
  return new Promise((resolve, reject) => {
    axios
      .post(url, JSON.stringify(data), {
        headers: {
          ...headers,
          Authorization: btoa(`${clientId}:${clientSecret}`),
        },
        withCredentials: true,
      })
      .then((response) => {
        storeUserDetailsToLocalStorage(response.data);
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function logout(history) {
  const url = `${baseURL}logout`;
  apiClient.post(url).then(() => postLogoutProcess(history));
}

// ******************** ALL ******************** \\
export function getUser() {
  const url = `${baseURL}user/get`;

  return apiClient.get(url, {
    headers: headers,
  });
}

export function uploadCaptions(jobId, file, format) {
  const url = `/transcription/upload-subtitles`;

  const formData = new FormData();
  formData.append('file', file);

  return apiClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      jobId,
      format,
    },
  });
}

export function uploadDocument(jobId, file) {
  const url = `/document/upload-document/${jobId}`;

  const formData = new FormData();

  formData.append('file', file);

  return apiClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function setNoteToJob(jobId, note) {
  return apiClient.put(`/jobs/notes`, note, { params: { jobId } });
}

export function acceptQCJob(jobId) {
  return apiClient.put(`/quality-controller/jobs/${jobId}`);
}

export function reportQCJob(jobId, issueType, note) {
  const params = {
    issueType,
  };
  return apiClient.patch(
    `/quality-controller/report/issue/${jobId}`,
    { note },
    {
      params,
    },
  );
}

export function reportTranscriberJob(jobId, issueType, note) {
  const params = {
    issueType,
  };
  return apiClient.patch(
    `jobs/transcriber/report/issue/${jobId}`,
    { note },
    {
      params,
    },
  );
}

export function submitStandardReview(jobId, note, ratings, status) {
  const data = {
    ratings,
    note,
    jobId,
    qualityCheckStatus: status,
  };

  return apiClient.post('/quality-controller/quality-checks/transcription', {
    ...data,
  });
}

export function submitCaptionsHQReview(jobId, note, ratings, status) {
  const data = {
    ratings,
    note,
    jobId,
    qualityCheckStatus: status,
  };

  return apiClient.post('/quality-controller/quality-checks/hq-captions', {
    ...data,
  });
}

export async function exportOrDownloadDocxOrRtf(jobId, format) {
  const params = {
    format,
  };

  const result = await apiClient.get(`document/file/${jobId}`, {
    params,
    responseType: 'blob',
  });
  const blob = new Blob([result.data], { type: 'blob' });
  saveAs(blob, `${jobId}.${format}`);
  await updateLastTranscriberEdit(jobId);
}

export function submitTranslatedSubtitlesReview(jobId, note, ratings, status) {
  const data = {
    ratings,
    note,
    jobId,
    qualityCheckStatus: status,
  };

  return apiClient.post('/quality-controller/quality-checks/translation', {
    ...data,
  });
}

export function updateWorkflowStyleAndStatus(
  jobId,
  workflowStyle,
  workflowStatus,
) {
  return apiClient.post(
    `/jobs/workflow`,
    {},
    {
      params: {
        jobId,
        workflowStyle,
        workflowStatus,
      },
    },
  );
}

export function getAvailableSubtitleFormats(jobId) {
  const URL = `${baseURL}transcription/subtitles-download-formats?jobId=${jobId}`;
  return apiClient.get(URL);
}

// ******************** ADMIN ******************** \\

export function getTranscribers(params) {
  return apiClient.get(`jobs/transcribers/list`, { params: { ...params } });
}

export function assignPJMToJob(jobIds, userName) {
  return apiClient.post(`jobs/admin/assignPJMToJob`, {
    userName,
    jobIds: [...jobIds],
  });
}

export function assignPJMToTranscriber(transcriber, pjm) {
  return apiClient.post(
    `jobs/admin/assignPJMToTranscriber`,
    {},
    { params: { transcriber, pjm } },
  );
}

export function removePJMFromTranscriber(transcriber, pjm) {
  return apiClient.post(
    `jobs/admin/removePJMFromTranscriber`,
    {},
    { params: { transcriber, pjm } },
  );
}

export function cancelJobRequest(jobId) {
  return apiClient.post(`jobs/admin/cancelJobs`, [jobId]);
}

export function downloadAudio(jobId) {
  return apiClient.get(`jobs/downloadoriginalmedia`, { params: { jobId } });
}

export function updateTranscriberDeadline(jobIds, deadline) {
  return apiClient.post(`jobs/admin/transcriberDeadline`, {
    jobIds,
    deadline,
  });
}

export function updateQualityControllerDeadline(jobIds, deadline) {
  return apiClient.post(`jobs/quality-control-deadline`, {
    jobIds,
    deadline,
  });
}

export function updateClientDeadline(jobIds, deadline) {
  return apiClient.post(`jobs/deadline`, {
    jobIds,
    deadline,
  });
}
export function notifyClient(jobId) {
  return apiClient.post(`admin/user/deadline-notification?jobId=${jobId}`);
}
// ******************** PERFECT JOB MANAGER ******************** \\
const getKeyValueOperatorData = (filters) => {
  return Object.keys(filters)
    .map((curr) => {
      const { values, value, operator } = filters[curr];
      const filterObject = {
        fieldName:
          curr === 'minAudioDuration' || curr === 'maxAudioDuration'
            ? 'nrAudioSeconds'
            : curr,
        operator,
      };
      if (values  && value !== "undefined" && values.length) {
        filterObject.values = values;
      } else if (value !== undefined && value !== "undefined") {
        filterObject.value = value;
      }
      return filterObject;
    })
    .filter((item) => (item.values || item.value) && item.operator);
};

export function getJobs(params) {
  const {
    order,
    page,
    sortProperty,
    sortDirection,
    pageSize,
    clientExactMatch,
    ...filters
  } = params;
  const keyValueOperators = getKeyValueOperatorData(filters);
  return apiClient.post(`jobs/list`, {
    query: keyValueOperators,
    order,
    page,
    sortProperty,
    sortDirection: sortDirection.toUpperCase(),
    clientExactMatch: clientExactMatch?.value,
    pageSize,
  });
}

export function getFiltersOptions() {
  return apiClient.get(`jobs/filtersOptions`);
}

export function getQualityControlJobs(status, page = 0, pageSize = 20) {
  return apiClient.get(`quality-controller/jobs`, {
    params: { status, page, pageSize },
  });
}

export function getQualityChecks(
  qualityControllerUserName = null,
  transcriberUserName = null,
  jobId = null,
  page = 0,
  pageSize = 20,
) {
  return apiClient.get(`quality-controller/quality-checks`, {
    params: {
      transcriberUserName,
      jobId,
      qualityControllerUserName,
      page,
      pageSize,
    },
  });
}

export function getWorkflowStatuses() {
  return apiClient.get(`/list-item/?listName=workflowStatus`);
}

export function getWorkflowStyles() {
  return apiClient.get(`/list-item/?listName=workflowStyle`);
}

export function getJobSources() {
  return apiClient.get(`/list-item/job-sources`);
}

export function searchFileNames(keyword) {
  return apiClient.get(`jobs/searchFileNames`, {
    params: { keyword: keyword },
  });
}

export function invite(transcribers, jobIds) {
  return apiClient.post(`jobs/invite`, {
    transcribersNames: transcribers,
    jobIds: [...jobIds],
  });
}

export function assignQualityControllers(qualityController, jobIds) {
  return apiClient.put(`quality-controller/jobs/assign`, {
    userName: qualityController,
    jobIds: [...jobIds],
  });
}

export function setSkipQualityControl(jobId, qualityControlSkip) {
  return apiClient.put(`jobs/flag-job-quality-control-skip/${jobId}`, {
    qualityControlSkip,
  });
}

export function setQualityController(qualityController, jobIds) {
  return apiClient.patch(
    `quality-controller/jobs/${jobIds.pop()}/quality-controller`,
    null,
    {
      params: {
        qualityController,
      },
    },
  );
}

export function assign(transcriber, jobIds) {
  return apiClient.post(`jobs/assign`, {
    transcriberName: transcriber,
    jobIds: [...jobIds],
  });
}

export function getTranscript(jobId) {
  return apiClient.get(`document/transcription/${jobId}`);
}

export function getAudioLink(jobId) {
  return apiClient.get(`media/audio/link`, {
    params: { jobId },
  });
}

export function acceptQuality(jobId) {
  return apiClient.post(
    `jobs/quality-check-accepted`,
    {},
    { params: { jobId } },
  );
}

export function denyQuality(jobId) {
  return apiClient.post(`jobs/quality-check-denied`, {}, { params: { jobId } });
}

export function changeStatusToBusyForTranslation(jobId) {
  return apiClient.post(`jobs/status`, {}, { params: { jobId } });
}

export function uploadSrtFile(file, jobId) {
  const formData = new FormData();
  formData.append('file', file);

  return apiClient.post(`jobs/uploadSrt`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { jobId },
  });
}

export function resetJobStatus(jobId) {
  return apiClient.post(`jobs/reopen-perfect-job`, {}, { params: { jobId } });
}

// ******************** TRANSCRIBER ******************** \\

export function getCurrentJobs() {
  return apiClient.get(`jobs/transcribers/currentJobs`);
}

export function getCurrentJobsWithAllocation() {
  return apiClient.get(`jobs/transcribers/currentJobsWithAllocation`);
}

export function getApprovedJobs(email) {
  return apiClient.get('/freelancer-invoice/freelancerInvoiceItems', {params: { email }});
}

export function getInvitationRequests() {
  return apiClient.get(`jobs/transcribers/invitationRequests`);
}

export function getAcceptedJobs() {
  return apiClient.get(`jobs/transcribers/acceptedJobs`);
}

export function startJob(jobId) {
  return apiClient.post(
    `jobs/transcribers/start-editing`,
    {},
    { params: { jobId } },
  );
}

export function acceptRequest(perfectJobRequest) {
  const jobIds = perfectJobRequest.transcriberInvitationJobs.map(
    (job) => job.jobId,
  );
  return apiClient.post(`jobs/transcribers/acceptRequest`, {
    perfectRequestId: perfectJobRequest.perfectRequestId,
    jobIds,
  });
}

export function denyRequest(perfectJobRequest) {
  const jobIds = perfectJobRequest.transcriberInvitationJobs.map(
    (job) => job.jobId,
  );
  return apiClient.post(`jobs/transcribers/denyRequest`, {
    perfectRequestId: perfectJobRequest.perfectRequestId,
    jobIds,
  });
}

export function submitJob(jobId) {
  return apiClient.post(
    `jobs/transcribers/submit-perfect-file`,
    {},
    { params: { jobId } },
  );
}

export function getJobDetails(jobId) {
  return apiClient.get(`jobs/perfect/status`, { params: { jobId: jobId } });
}

export async function exportJsonFormat(jobId) {
  const URL = `/export/${jobId}/json`;

  const res = await apiClient.get(URL);

  const blob = new Blob([JSON.stringify(res.data)], {
    type: 'text/html;charset=utf-8',
  });
  saveAs(blob, `${jobId}.json`);
  await updateLastTranscriberEdit(jobId);
}

export async function updateLastTranscriberEdit(jobId) {
  return apiClient.put('jobs/metadata/last-transcriber-edit-exports', null, {
    params: { jobId },
  });
}

/**
 * Exports the latest SRT file
 * @param {string} jobId
 */
export async function exportLatestSrt(jobId) {
  const URL = `/export/${jobId}/srt`;

  const res = await apiClient.get(URL);

  const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
  saveAs(blob, `${jobId}.srt`);
  await updateLastTranscriberEdit(jobId);
}

/**
 * Exports the latest STL file
 * @param {string} jobId
 */
export async function exportLatestStl(jobId) {
  const URL = `/export/${jobId}/stl`;

  const res = await apiClient.get(URL);

  window.open(res.data, '_self');
  await updateLastTranscriberEdit(jobId);
}

/**
 *
 * @param {String} jobId
 * @param {String} role selected role(comes from global state)
 * @return {Promise<{editorEnabled: boolean, availableExports: Array<string>}>}
 */
export async function getAvailableExports(jobId, role) {
  const URL = `/user/freelancer/${jobId}/export-options`;
  const params = { selectedRole: role };
  const res = await apiClient.get(URL, { params });

  return res.data;
}

export async function exportTranscriberLatestSRT(jobId) {
  return Promise.all([
    exportLatestSrt(jobId),
  ]);
}

export async function exportLatestVtt(jobId) {
  const URL = `/export/${jobId}/vtt`;

  const res = await apiClient.get(URL);

  const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
  saveAs(blob, `${jobId}.vtt`);
  await updateLastTranscriberEdit(jobId);
}

export async function exportTxt(jobId) {
  const URL = `/export/${jobId}/txt`;

  const res = await apiClient.get(URL);

  const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
  saveAs(blob, `${jobId}.txt`);
}

export async function exportDoc(jobId) {
  const res = await axios.get(wordExportEndpoint, {
    params: { jobId },
  });
  const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
  saveAs(blob, `${jobId}.doc`);
}

/**
 * Method that replaces the function dedicated to exporting SRT only.
 *
 * In the case of STL files, response bodies have a downloadUrl attribute instead
 * of the actual text. Thus, a new window is opened pointing to that particular URl
 *
 * Can handle any subtitle/caption extension as long as it is supported
 * (only SRT,VTT,EBU-STL as of v1.65)
 *
 * Will save using the recordId if possible
 * @deprecated
 * @param {String} recordId the reference to the record that is being transcribed by a transcriber
 * @param {String} format the extension of the generated file
 * @param endpoint
 * @param type
 * @returns {Promise} returns promise in order to handle error in case it is rejected
 */
export function exportSubAsFile(recordId, endpoint, format, type) {
  let params;
  // todo: consult with BE and refator this. v2 endpoints SEEM to be using jobid
  if (format === 'json') {
    params = {
      recordId,
      maxCharsPerRow: 42,
      maxNumberOfRows: 2,
      maxScreenTimePerRowSeconds: 3500,
    };
  } else {
    if (type === 'subtitles') {
      params = {
        recordId,
        maxCharsPerRow: 42,
        maxNumberOfRows: 2,
        maxScreenTimePerRowSeconds: 3500,
      };
    } else {
      params = {
        jobId: recordId,
        maxCharsPerRow: 42,
        maxNumberOfRows: 2,
        maxScreenTimePerRowSeconds: 3500,
      };
    }
  }

  return apiClient
    .get(endpoint, {
      params,
    })
    .then((res) => {
      if (format === 'stl') {
        const url = res.data.downloadUrl;
        window.open(url, '_blank');
        return;
      }
      if (format === 'json') {
        const blob = new Blob([JSON.stringify(res.data)], {
          type: 'text/html;charset=utf-8',
        });
        saveAs(blob, `${recordId}.${format}`);
        return;
      }
      const blob = new Blob([res.data], { type: 'text/html;charset=utf-8' });
      saveAs(blob, `${recordId}.${format}`);
    })
    .catch((err) => {
      throw err.response.data;
    });
}

/**
 * Exports the video file in a compressed format and size using an S3 link
 * @param {*} recordId the recordId referencing the video file
 */
export function exportLowQualityVideo(recordId) {
  apiClient
    .get(`userrecord/${recordId}/compressed-media-link`, {})
    .then((res) => {
      const downloadUrl = res.data.downloadUrl;
      window.open(downloadUrl, '_blank');
    })
    .catch((err) => {
      console.error(err);
    });
}

/**
 * Exports the video file file of the user in its original
 * format and size using an S3 link.
 * @param {*} recordId the recordId referencing the video file
 */
export function exportHighQualityVideo(recordId) {
  apiClient
    .get(`userrecord/${recordId}/original-media-link`, {})
    .then((res) => {
      const downloadUrl = res.data.downloadUrl;
      window.open(downloadUrl, '_blank');
    })
    .catch((err) => {
      console.error(err);
    });
}

export function loadSrtAsText(jobId) {
  const url = `${baseURL}jobs/export?jobId=${jobId}&format=srt`;
  return apiClient.get(url);
}

export function exportSubAsWord(recordId, { transcript }) {
  return apiClient
    .post(wordExportEndpoint, transcript, {
      params: {
        recordId,
        includeTimestamps: true,
        includeSpeakers: true,
        includeHighlights: false,
        highlightsOnly: false,
      },
      responseType: 'arraybuffer',
      withCredentials: false,
    })
    .then((res) => {
      const blob = new Blob([res.data], { type: 'application/octet-stream' });
      saveAs(blob, `${recordId}.docx`);
    });
}

/**
 * Returns the glossary with the corresponding id
 * @param {string} glossaryId
 * @returns Promise
 */
export function getGlossaryById(glossaryId) {
  return apiClient.get(`glossary/${glossaryId}`);
}
