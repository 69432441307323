import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Slider,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { filter } from 'lodash';
import CustomSelection from '../common/fields/selection';
import Autosuggest from '../common/fields/autosuggest';
import SelectAutosuggest from '../common/fields/autoCompleteMaterialUI';
import ValueLabel from '../common/fields/valueLabel';

import {
  BUSY,
  ERROR,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_CANCELLED,
  PERFECT_DONE,
  PERFECT_EDITING,
  PERFECT_IN_QUEUE,
  PERFECT_IN_REVIEW,
  PERFECT_OPEN,
  PERFECT_REPORTED,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_TO_BE_REVIEWED,
} from '../../constants/jobStatuses';
import {
  CAPTIONS,
  TRANSCRIPTION,
  TRANSLATED_SUBTITLES,
  TRANSLATION,
} from '../../constants/transcriptionTypes';
import { ADMIN } from '../../roles';
import {
  getJobSources,
  getWorkflowStatuses,
  getWorkflowStyles,
} from '../../utils/api';
import { addParamInUrl, clearParamsInUrl } from '../../helpers/urlHandler';
import {
  currentFiltersState,
  MAX_AUDIO_DURATION,
} from '../../store/filterStore';
import { getRoles } from '../../utils/auth';
import MultiSelect from '../common/fields/multiSelect';

const filterStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterBox: {
    maxWidth: 200,
  },
  actions: {
    width: 200,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  durationSlider: {},
}));

const statusOptions = [
  PERFECT_OPEN,
  PERFECT_AWAITING_CONFIRMATION_TRANSCRIBER,
  PERFECT_IN_QUEUE,
  PERFECT_EDITING,
  PERFECT_TO_BE_REVIEWED,
  PERFECT_REVIEW_NOT_PASSED,
  PERFECT_DONE,
  PERFECT_CANCELLED,
  BUSY,
  PERFECT_REPORTED,
  ERROR,
  PERFECT_IN_REVIEW,
];

const transcriptionTypeOptions = [
  TRANSCRIPTION,
  TRANSLATION,
  CAPTIONS,
  TRANSLATED_SUBTITLES,
];

export default function TableToolbar(props) {
  const classes = filterStyles();
  const [currentFilters, setCurrentFilters] =
    useRecoilState(currentFiltersState);
  const resetFilters = useResetRecoilState(currentFiltersState);
  const [selectedTranscribers, setSelectedTranscribers] = React.useState(
    currentFilters.transcriber.values,
  );
  const [selectedQC, setSelectedQC] = React.useState(
    currentFilters.qualityController.values,
  );
  const [workflowStylesData, setWorkflowStylesData] = React.useState([]);
  const [workflowStatusesData, setWorkflowStatusesData] = React.useState([]);
  const [jobSourcesData, setJobSourcesData] = React.useState([]);
  const [minMaxDuration, setMinMaxDuration] = React.useState([
    0,
    MAX_AUDIO_DURATION * 60,
  ]);
  const [exact, setExact] = React.useState(true);
  const [unlockedJobsOnly, setUnlockedJobsOnly] = React.useState(false);

  const history = useHistory();
  const getFieldOperator = (key) => ({
    operator: currentFilters[key].operator,
  });

  const {
    filterOptions,
    setIsFilterApplied,
    onChangeFileNameFilter,
    onSearch,
  } = props;

  const [t] = useTranslation();
  const role = getRoles();
  function updateFilter(key, value, multiple) {
    const newFilters = JSON.parse(JSON.stringify(currentFilters));
    const parsedKey = key?.split('-')[0];
    if (parsedKey === 'status' && !value) {
      setUnlockedJobsOnly(false);
      newFilters.notificationCounterForAssignedTranscriber.value = '';
    }

    if (multiple) {
      newFilters[parsedKey].values = value || [];
    } else {
      newFilters[parsedKey].value = value || '';
    }
    setCurrentFilters(newFilters);
  }
  function handleSelectTranscriber(value) {
    setSelectedTranscribers(value);
    updateFilter('transcriber', value, true);
  }
  function handleSelectQC(value) {
    setSelectedQC(value);
    updateFilter('qualityController', value, true);
  }
  function handleChangeDurationSlider(event, newValue) {
    const [minValue, maxValue] = newValue;
    const newMinValueObject = {
      minAudioDuration: {
        ...getFieldOperator('minAudioDuration'),
        value: minValue * 60,
      },
    };
    const newMaxValueObject = {
      maxAudioDuration: {
        ...getFieldOperator('maxAudioDuration'),
        value: maxValue * 60,
      },
    };
    setCurrentFilters({
      ...currentFilters,
      ...newMinValueObject,
      ...newMaxValueObject,
    });
    setMinMaxDuration(newValue);
  }

  function adjustFiltersParamsInUrl() {
    clearParamsInUrl(history);
    const filterKeys = Object.keys(currentFilters);
    filterKeys.forEach((key) => {
      const { value, values, operator } = currentFilters[key];
      if (
        key === 'notificationCounterForAssignedTranscriber' &&
        !!currentFilters.status.value
      ) {
        addParamInUrl(history, key, operator);
      } else if (value !== '' || values) {
        addParamInUrl(history, key, value || values);
      }
    });
  }

  function onResetFilters() {
    setExact(true);
    resetFilters();
    setSelectedQC([]);
    setSelectedTranscribers([]);
    clearParamsInUrl(history);

    onSearch({});
  }

  function onSearchByFilters() {
    adjustFiltersParamsInUrl();

    onSearch(currentFilters);
  }

  function getListOfWorkflowStatuses() {
    getWorkflowStatuses().then((res) => {
      setWorkflowStatusesData(Object.values(res?.data || {}));
    });
  }

  function getListOfWorkflowStyles() {
    getWorkflowStyles().then((res) => {
      setWorkflowStylesData(Object.values(res?.data || {}));
    });
  }

  function getListOfSources() {
    getJobSources().then((res) => {
      setJobSourcesData(res?.data || []);
    });
  }

  const configureNotificationCounterForAssignedTranscriber = (
    filters,
    unlockedJobs,
  ) => {
    filters.notificationCounterForAssignedTranscriber.value = 2;
    if (unlockedJobs) {
      filters.notificationCounterForAssignedTranscriber.operator = 'eq';
      return filters;
    }
    filters.notificationCounterForAssignedTranscriber.operator = 'lte';
    return filters;
  };

  function getAppendedFiltersInUrl() {
    let filters = {};

    const { search } = history?.location;
    const params = search.replaceAll('?', '').split('&');
    params.forEach((param) => {
      const keyValue = param.split('=');
      if (keyValue && keyValue[0] && keyValue[1]) {
        filters = {
          ...filters,
          [keyValue[0]]: keyValue[1],
        };
      }
    });

    return filters;
  }

  function setDefaultValueOfFiltersBasedOnUrlParams() {
    const filters = getAppendedFiltersInUrl();
    const currentStateFilter = JSON.parse(JSON.stringify(currentFilters));
    if (filters) {
      const filterKeys = Object.keys(filters);
      filterKeys.forEach((filterKey) => {
        if (filters[filterKey]) {
          currentStateFilter[filterKey].value = filters[filterKey];
          if (filterKey === 'clientExactMatch') {
            const isExact = filters.clientExactMatch === 'true';
            setExact(isExact);
            currentStateFilter.clientExactMatch.value = isExact;
          }

          if (filterKey === 'notificationCounterForAssignedTranscriber') {
            const urlFilterUnlockedJobsOnly =
              filters.notificationCounterForAssignedTranscriber === 'eq';
            setUnlockedJobsOnly(urlFilterUnlockedJobsOnly);
            configureNotificationCounterForAssignedTranscriber(
              currentStateFilter,
              urlFilterUnlockedJobsOnly,
            );
          }
          if (filterKey === 'minAudioDuration') {
            currentStateFilter.minAudioDuration.value = filters[filterKey];
          }
          if (filterKey === 'maxAudioDuration') {
            currentStateFilter.maxAudioDuration.value = filters[filterKey];
          }
          if (
            filters[filterKey] !== 'undefined' &&
            (filterKey === 'language' ||
              filterKey === 'transcriber' ||
              filterKey === 'qualityController' ||
              filterKey === 'jobSource' ||
              filterKey === 'status')
          ) {
            const queryResult = filters[filterKey].split(',');
            currentStateFilter[filterKey].values = queryResult;
            if (filterKey === 'transcriber') {
              setSelectedTranscribers(queryResult);
            }
            if (filterKey === 'qualityController') {
              setSelectedQC(queryResult);
            }
          }
        }
      });

      setMinMaxDuration([
        currentStateFilter.minAudioDuration.value / 60,
        currentStateFilter.maxAudioDuration.value / 60,
      ]);
      setCurrentFilters(currentStateFilter);
      onSearch(currentStateFilter);
    }
  }

  useEffect(() => {
    if (
      workflowStatusesData.length > 0 &&
      workflowStylesData.length > 0 &&
      jobSourcesData.length
    ) {
      const {
        languages,
        perfectJobManagers,
        transcribers,
        qualityControllers,
      } = filterOptions;
      if (
        languages &&
        perfectJobManagers &&
        transcribers &&
        qualityControllers
      ) {
        setDefaultValueOfFiltersBasedOnUrlParams();
      }
      setIsFilterApplied(true);
    }
  }, [workflowStatusesData, workflowStylesData, jobSourcesData, filterOptions]);

  const handleClientExactMatchChange = () => {
    const newFilters = JSON.parse(JSON.stringify(currentFilters));
    newFilters.clientExactMatch.value = !exact;
    setCurrentFilters(newFilters);
    setExact(!exact);
  };

  const handleJobUnlockChange = () => {
    setUnlockedJobsOnly(!unlockedJobsOnly);
    let newFilters = JSON.parse(JSON.stringify(currentFilters));
    newFilters = configureNotificationCounterForAssignedTranscriber(
      newFilters,
      !unlockedJobsOnly,
    );
    setCurrentFilters(newFilters);
  };

  useEffect(() => {
    getListOfWorkflowStatuses();
    getListOfWorkflowStyles();
    getListOfSources();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.filtersContainer}>
        <div className={classes.filterBox}>
          <Typography variant="h6" id="tableTitle">
            {t('managerDashboard.jobFilters.title')}
          </Typography>
        </div>
        {/* JOB ID */}
        <div className={classes.filterBox}>
          <TextField
            id="_id"
            label={t('managerDashboard.jobFilters.jobId')}
            value={currentFilters._id.value}
            onChange={(e) => updateFilter(e.target.id, e.target.value)}
          />
        </div>

        <div className={classes.filterBox}>
          <TextField
            id="externalJobIdentifier"
            label={t('common.jobFields.externalJobIdentifier')}
            value={currentFilters.externalJobIdentifier.value}
            onChange={(e) => updateFilter(e.target.id, e.target.value)}
          />
        </div>
        <div className={classes.filterBox}>
          <TextField
            id="externalCustomerIdentifier"
            label={t('common.jobFields.externalCustomerIdentifier')}
            value={currentFilters.externalCustomerIdentifier.value}
            onChange={(e) => updateFilter(e.target.id, e.target.value)}
          />
        </div>
        {/* Client */}
        <div className={classes.filterBox} style={{ marginBottom: 32 }}>
          <TextField
            id="client"
            label={t('managerDashboard.jobFilters.client')}
            value={currentFilters.client.value}
            onChange={(e) => updateFilter(e.target.id, e.target.value)}
            style={{ marginBottom: 0 }}
          />
          <FormControlLabel
            style={{ height: 35, marginLeft: -3 }}
            control={
              <Checkbox
                checked={exact}
                onChange={() => handleClientExactMatchChange()}
                name="checkedExact"
                style={{ padding: 0, color: '#005A50', marginRight: 4 }}
              />
            }
            label={
              <span style={{ fontSize: 14 }}>
                {t('managerDashboard.jobFilters.exactMatch')}
              </span>
            }
          />
        </div>
        {/* STATUS */}
        <div className={classes.filterBox} style={{ marginBottom: 32 }}>
          <MultiSelect
            id="status"
            label={t('managerDashboard.jobFilters.status')}
            value={currentFilters.status.values || []}
            data={statusOptions}
            onChange={(name, value) => updateFilter(name, value, true)}
            style={{ marginBottom: 0 }}
          />
          <FormControlLabel
            style={{ height: 35, marginLeft: -3 }}
            control={
              <Checkbox
                checked={unlockedJobsOnly}
                onChange={() => handleJobUnlockChange()}
                disabled={!currentFilters.status.value}
                name="notificationCounterForAssignedTranscriber"
                style={{ padding: 0, color: '#005A50', marginRight: 4 }}
              />
            }
            label={
              <span style={{ fontSize: 14 }}>
                {t(
                  'managerDashboard.jobFilters.notificationCounterForAssignedTranscriber',
                )}
              </span>
            }
          />
        </div>

        {/* WORKFLOW STYLE */}
        <div className={classes.filterBox}>
          <CustomSelection
            id="workflowStyle"
            label={t('managerDashboard.table.headers.workflowStyle')}
            value={currentFilters.workflowStyle.value}
            data={workflowStylesData}
            onChange={(e) => updateFilter(e.target.name, e.target.value)}
          />
        </div>
        {/* WORKFLOW STATUS */}
        <div className={classes.filterBox}>
          <CustomSelection
            id="workflowStatus"
            label={t('managerDashboard.table.headers.workflowStatus')}
            value={currentFilters.workflowStatus.value}
            data={workflowStatusesData}
            onChange={(e) => updateFilter(e.target.name, e.target.value)}
          />
        </div>

        <div className={classes.filterBox}>
          <MultiSelect
            id="jobSource"
            label={t('common.jobFields.jobSource')}
            value={currentFilters.jobSource.values || []}
            data={jobSourcesData}
            onChange={(name, value) => updateFilter(name, value, true)}
          />
        </div>
        {/* TRANSCRIPTION TYPE */}
        <div className={classes.filterBox}>
          <CustomSelection
            id="transcriptionType"
            label={t('managerDashboard.jobFilters.transcriptionType')}
            value={currentFilters.transcriptionType.value}
            data={transcriptionTypeOptions}
            onChange={(e) => updateFilter(e.target.name, e.target.value)}
          />
        </div>
        {/* LANGUAGE */}
        <div className={classes.filterBox}>
          <MultiSelect
            id="language"
            label={t('managerDashboard.jobFilters.language')}
            value={currentFilters.language.values || []}
            data={filterOptions.languages}
            type="lang"
            onChange={(name, value) => updateFilter(name, value, true)}
          />
        </div>
        {role.includes(ADMIN) && (
          <div className={classes.filterBox}>
            <SelectAutosuggest
              isMulti={false}
              label={t('managerDashboard.jobFilters.perfectJobManager')}
              value={currentFilters.perfectJobManager.value}
              id="perfectJobManager"
              onChange={(newValue, e) => updateFilter(e.target.id, newValue)}
              placeholder={t('managerDashboard.jobFilters.perfectJobManager')}
              suggestions={filterOptions.perfectJobManagers.map(
                (suggestion) => ({
                  value: suggestion,
                  label: suggestion,
                }),
              )}
            />
          </div>
        )}
        {/* TRANSCRIBER */}
        <div className={classes.filterBox}>
          <SelectAutosuggest
            isMulti
            label={t('managerDashboard.jobFilters.transcriber')}
            value={selectedTranscribers}
            id="transcriber"
            onChange={handleSelectTranscriber}
            placeholder={t('managerDashboard.jobFilters.transcriber')}
            suggestions={filterOptions.transcribers.map((suggestion) => ({
              value: suggestion,
              label: suggestion,
            }))}
          />
        </div>
        <div className={classes.filterBox}>
          <SelectAutosuggest
            isMulti
            label={t('common.jobFields.qualityController')}
            value={selectedQC}
            id="qualityController"
            onChange={handleSelectQC}
            placeholder={t('common.jobFields.qualityController')}
            suggestions={filterOptions.qualityControllers.map((suggestion) => ({
              value: suggestion,
              label: suggestion,
            }))}
          />
        </div>
        {/* DEADLINE */}
        <div className={classes.filterBox}>
          <TextField
            label={t('managerDashboard.jobFilters.deadline')}
            placeholder={t('managerDashboard.jobFilters.deadline')}
            id="deadline"
            type="date"
            value={currentFilters.deadline.value}
            onChange={(e) => updateFilter(e.target.id, e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        {/* NAME */}
        <div className={classes.filterBox}>
          <Autosuggest
            id="displayFileName"
            label={t('managerDashboard.jobFilters.name')}
            placeholder={t('managerDashboard.jobFilters.name')}
            onChange={(newValue, e) => updateFilter(e.target.id, newValue)}
            onFetchSuggestions={onChangeFileNameFilter}
            value={currentFilters.displayFileName.value}
            data={[]}
          />
        </div>
        {/* AUDIO DURATION */}
        <div className={classes.filterBox}>
          <InputLabel shrink htmlFor="range-slider">
            {t('managerDashboard.jobFilters.audioDuration')}
          </InputLabel>
          <Slider
            className={classes.durationSlider}
            value={minMaxDuration}
            min={0}
            step={20}
            max={MAX_AUDIO_DURATION}
            valueLabelFormat={(v) => `${v} ${v === 0 ? 'minute' : 'minute'}`}
            onChange={handleChangeDurationSlider}
            valueLabelDisplay="auto"
            ValueLabelComponent={ValueLabel}
            aria-labelledby="range-slider"
          />
        </div>
        {/* BUTTONS */}
        <div className={classes.actions}>
          <Button onClick={() => onResetFilters()} color="secondary">
            {t('common.button.reset')}
          </Button>
          <Button onClick={onSearchByFilters} color="primary">
            {t('common.button.search')}
          </Button>
        </div>
      </div>
    </div>
  );
}

TableToolbar.propTypes = {
  filterOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  onChangeFileNameFilter: PropTypes.func.isRequired,
  setIsFilterApplied: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

TableToolbar.defaultProps = {
  filterOptions: {},
};
