import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRecoilValueLoadable } from 'recoil';
import {
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
  TRANSCRIPTION_TYPE_CAPTIONS,
  TRANSCRIPTION_TYPE_TRANSCRIPTION,
  TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES,
} from '../../constants/constants';
import AmberButton from '../common/amberbutton';
import { PERFECT_REVIEW_NOT_PASSED } from '../../constants/jobStatuses';
import UploadConfirmationModal from './uploadConfirmationModal';
import JobDetailsCard from '../common/job_details_card/JobDetailsCard';
import UploadCaptions from './uploadCaptions';
import { EDITOR_LINK } from '../../settings';
import JobEmptyState from '../quality_control/jobs/jobEmptyState/jobEmptyState';
import AmberTextButton from '../buttons/AmberTextButton';
import { getExportOptionsSelectorFamily } from '../../store/jobManagerDashboard';
import useSnackbar from '../../utils/useSnackbar';
import CircularIndeterminate from '../loading/indeterminateSpinner';
import JobGuidnessDialog from '../common/job_guidness_dialog';

const shouldShowUploadCaptionsBtn = (uploadOptions) => !!uploadOptions?.length;

function openEditor(toEditorLink) {
  window.open(toEditorLink, '_blank');
}

const getButtonName = (transcriptionType, t) => {
  switch (transcriptionType) {
    case TRANSCRIPTION_TYPE_TRANSLATED_SUBTITLES:
      return t('transcriberDashBoard.currentJobCard.uploadSubtitles');

    case TRANSCRIPTION_TYPE_CAPTIONS:
      return t('transcriberDashBoard.currentJobCard.uploadCaptions');
    default:
      return t('transcriberDashBoard.currentJobCard.uploadFile');
  }
};

function ActionButtons({ job, jobDetailsAction, uploadCaptionsAction, setOpenGuidness, setJobGuidnessDetails}) {
  const [t] = useTranslation();
  const { showError } = useSnackbar();
  const { glossaryId, jobId, transcriptionType, status } = job;
  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(jobId),
  );
  const isNotReviewNotPassed = status === PERFECT_REVIEW_NOT_PASSED;
  const toEditorLink = `${EDITOR_LINK}/${jobId}?workMode=perfectPlatform`;

  const checkIfCanEdit = () => exportOptions?.contents?.editorEnabled;
  const externalEditorLink = job?.editorConfig?.editorLink;
  // FIXME needs to USE HOC COMPONENT, this is duplicated code (DRY be damned)
  const isDataLoading = () =>
    exportOptions.state === RECOIL_LOADABLE_LOADING_STATE;

  const showDataError = () => {
    if (exportOptions.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(exportOptions.contents.response.data.message);
    }
  };

  const triggerOpenEditor = (toEditorLink, externalEditorLink) =>{
   if (externalEditorLink){
      setOpenGuidness(true)
      setJobGuidnessDetails(job)
    }
    else{
      openEditor(toEditorLink)
    }
 
  }
  useEffect(() => {
    showDataError();
  }, [exportOptions.state]);

  if (isDataLoading()) {
    return <CircularIndeterminate thickness={3.6} />;
  }
  return (
    <Box display="flex" justifyContent="end">
      <AmberTextButton
        onClick={() => jobDetailsAction(jobId, glossaryId)}
        text={t('common.jobItemDialog.detailsTab')}
      />
      <Box mr={1}>
        {shouldShowUploadCaptionsBtn(
          exportOptions?.contents?.uploadOptions,
        ) && (
          <AmberButton
            color="primary"
            onClick={() => uploadCaptionsAction(job)}
          >
            {getButtonName(transcriptionType, t)}
          </AmberButton>
        )}
      </Box>
      <div>
        {checkIfCanEdit() && (
          <AmberButton onClick={() => triggerOpenEditor(toEditorLink, externalEditorLink)}>
            {externalEditorLink ? t('transcriberDashBoard.currentJobCard.continueButtonExternal'): t('transcriberDashBoard.currentJobCard.continueButton')}
          </AmberButton>
        )}
        {isNotReviewNotPassed && (
          <Typography color="textSecondary">
            {t('transcriberDashBoard.currentJobCard.reviewNotPassedNote')}
          </Typography>
        )}
      </div>
    </Box>
  );
}

ActionButtons.propTypes = {
  job: PropTypes.objectOf(PropTypes.string).isRequired,
  jobDetailsAction: PropTypes.func.isRequired,
  uploadCaptionsAction: PropTypes.func.isRequired,
  setJobGuidnessDetails:  PropTypes.func.isRequired,
};

function CurrentJobs({
  currentJobs,
  submitJobHandler,
  showJobDetails,
  setTabValue
}) {
  const [t] = useTranslation();
  const [openUploadCaptions, setOpenUploadCaptions] = useState(false);
  const [openGuidness, setOpenGuidness] = useState(false);
  const [jobGuidnessDetails, setJobGuidnessDetails] = useState({});

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [jobDetailsCaptionsDialog, setJobDetailsCaptionsDialog] = useState({});
  
  function setJobToUploadCaptions(jobToDisplay) {
    setJobDetailsCaptionsDialog(jobToDisplay);
    setOpenUploadCaptions(true);
  }

  function closeGuidnessDialog(){
    setOpenGuidness(false)
  }

  function openExternalLink(){
    const win = window.open( job?.editorConfig?.editorLink, '_blank');
    win.focus();
  }


  function checkIfNoCurrentJobs() {
    return currentJobs && currentJobs.length === 0;
  }

  // No current jobs
  if (checkIfNoCurrentJobs()) {
    return (
      <JobEmptyState
        stringBold={t('transcriberDashBoard.emptyActiveJobs1')}
        stringSecondary={t('transcriberDashBoard.emptyActiveJobs2')}
      />
    );
  }

  // Show current jobs
  return (
    <>
      {currentJobs &&
        currentJobs.map((jobToDisplay) => (
          <Box key={jobToDisplay.jobId}>
            <UploadConfirmationModal
              open={openConfirmation}
              setOpen={setOpenConfirmation}
              onOkayClick={() => submitJobHandler(jobToDisplay.jobId)}
              title={
                TRANSCRIPTION_TYPE_TRANSCRIPTION ===
                jobToDisplay.transcriptionType
                  ? t(
                      'transcriberDashBoard.currentJobCard.transcriptionTitleConfirmation',
                    )
                  : t(
                      'transcriberDashBoard.currentJobCard.captionTitleConfirmation2',
                    )
              }
              description={
                TRANSCRIPTION_TYPE_TRANSCRIPTION ===
                jobToDisplay.transcriptionType
                  ? t(
                      'transcriberDashBoard.currentJobCard.transcriptionDescriptionConfirmation',
                    )
                  : t(
                      'transcriberDashBoard.currentJobCard.captionDescriptionConfirmation2',
                    )
              }
            />
            <JobDetailsCard
              showJobDetails={showJobDetails}
              jobToDisplay={jobToDisplay}
              actionButtons={
                <ActionButtons
                  job={jobToDisplay}
                  jobDetailsAction={showJobDetails}
                  uploadCaptionsAction={setJobToUploadCaptions}
                  setOpenGuidness={setOpenGuidness}
                  setJobGuidnessDetails = {setJobGuidnessDetails}
                />
              }
            />
          </Box>
        ))}

      {jobDetailsCaptionsDialog && (
        <UploadCaptions
          openUploadCaptions={openUploadCaptions}
          setOpenUploadCaptions={setOpenUploadCaptions}
          jobDetails={jobDetailsCaptionsDialog}
          submitJob={submitJobHandler}
        />
      )}
      <JobGuidnessDialog
        open={openGuidness}
        handleClose={closeGuidnessDialog}
        job={jobGuidnessDetails}
      />
    </>
  );
}

export default CurrentJobs;

CurrentJobs.propTypes = {
  currentJobs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  submitJobHandler: PropTypes.func.isRequired,
  showJobDetails: PropTypes.func.isRequired,
  setTabValue: PropTypes.func,
};
CurrentJobs.defaultProps = {
  setTabValue: () => {},
};
