import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getRatingDefaults } from '../../../constants/ratingCategories';
import AmberSectionTitle from '../../labels/AmberSectionTitle';
import RatingAverageNumber from '../fields/ratingAverageNumber';
import AmberSimpleTextField from '../../textfields/amberSimpleTextfield';
import parseLines from '../../../utils/stringUtils';
import AmberNoItemFoundCard from '../../cards/AmberNoItemFound';
import FieldValue from '../fields/fieldValue';

const useStyles = makeStyles((theme) => ({
  keyTitle: {
    flex: '0 1 50%',
    color: theme.palette.custom.charcoalGray,
    fontWeight: '900',
    marginBottom: 0,
    maxHeight: '1.5em',
  },
  keyValue: {
    fontWeight: '900',
    padding: '0.1em 1em 0 1em',
    maxHeight: '1.2em',
  },
  keyValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0.5em 0em 0.5em 0em',
    alignItems: 'center',
  },
  ratingsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2em',
  },
  titleAndRatingContainer: {
    display: 'flex',
    alignItems: 'center', 
   
  },
  ratingTotal: {
    fontWeight: '900',
    flex: '0 1 46%',
    fontSize: '1.1em',
    marginTop: '0.7em',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function QualityCheckContents(props) {
  const { qualityCheck } = props;
  const classes = useStyles();
  const [t] = useTranslation();

  if (!qualityCheck.length) {
    return (
      <AmberNoItemFoundCard text={t('dialogJobFeedback.noQualityCheck')} />
    );
  }

  return (
    <>
      {!qualityCheck.length && (
        <AmberNoItemFoundCard text={t('dialogJobFeedback.noQualityCheck')} />
      )}

      {qualityCheck.length &&
        qualityCheck.map((check, index) => {
          const { note, transcriberRating, transcriptionType, workflowStyle } =
            check;
          const ratingDefaults = getRatingDefaults(
            t,
            workflowStyle,
            transcriptionType,
          );
          const ratingKeys = Object.keys(ratingDefaults);
          const count = index + 1;
          return (
            <div key={index} className={classes.ratingsContainer}>
              <div className={classes.titleAndRatingContainer}>
                <AmberSectionTitle
                  typographyClasses={{ root: classes.ratingTotal }}
                  text={t(`dialogJobFeedback.total`) + ' - ' + count}
                  typographyVariant="h6"
                />
                <RatingAverageNumber average={transcriberRating.average} />
              </div>

              

              {ratingKeys.map((key) => (
                <FieldValue
                  key={key}
                  fieldName={ratingDefaults[key].label}
                  value={transcriberRating[key]}
                  fieldClasses={{ root: classes.keyTitle }}
                  valueClasses={{ root: classes.keyValue }}
                  containerClassNames={classes.keyValueContainer}
                />
              ))}
              <div className={classes.textFieldContainer}>
                <AmberSectionTitle
                  typographyClasses={{ root: classes.ratingTotal }}
                  text={t('dialogJobFeedback.notes')}
                  typographyVariant="caption"
                />
                <AmberSimpleTextField
                  multiline
                  value={note ? parseLines(note) : ''}
                  disabled
                  fullWidth
                  maxRows={20}
                  minRows={10}
                />
              </div>
            </div>
          );
        })}
    </>
  );
}

QualityCheckContents.propTypes = {
  qualityCheck: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.string,
      transcriptionType: PropTypes.string,
      workflowStyle: PropTypes.string,
      transcriberRating: PropTypes.shape({
        transcriptionContent: PropTypes.number,
        syntax: PropTypes.number,
        grammar: PropTypes.number,
        research: PropTypes.number,
        punctuation: PropTypes.number,
        difficulty: PropTypes.number,
        average: PropTypes.number,
      }),
    }),
  ),
};

QualityCheckContents.defaultProps = {
  qualityCheck: [],
};

export default QualityCheckContents;
