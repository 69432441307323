import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRecoilValueLoadable } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import Job from '../job';
import withQualityControlLoading from '../../../../containers/withQualityControlLoading';
import { getExportOptionsSelectorFamily } from '../../../../store/jobManagerDashboard';
import {
  RECOIL_LOADABLE_ERROR_STATE,
  RECOIL_LOADABLE_LOADING_STATE,
} from '../../../../constants/constants';
import useSnackbar from '../../../../utils/useSnackbar';
import AmberTextButton from '../../../buttons/AmberTextButton';
import generateKey from '../../../../utils/generateReactKey';

const useStyles = makeStyles(() => ({
  exportTextButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    justifyContent: 'flex-start',
    padding: 0,
  },
}));
const hasError = (recoilState) =>
  recoilState.state === RECOIL_LOADABLE_ERROR_STATE;

const getButtonActionTranslation = (editorEnabled, t) =>
  (!editorEnabled
    ? t('common.button.submitCheck')
    : t('common.button.editorOpen'));

function JobWrapper({
  job,
  handleOnClickReport,
  setIsLoading,
  handleRedirectToEditor,
  handleOpenReviewDialog,
}) {
  const classes = useStyles();

  const { _id, recordId, sourceJobId } = job;
  const exportOptions = useRecoilValueLoadable(
    getExportOptionsSelectorFamily(_id),
  );
  const [t] = useTranslation();
  const { showError } = useSnackbar();

  useEffect(() => {
    if (exportOptions.state === RECOIL_LOADABLE_ERROR_STATE) {
      showError(exportOptions.contents.response.data.message);
    }
    setIsLoading(exportOptions.state === RECOIL_LOADABLE_LOADING_STATE);
  }, [exportOptions]);

  const isDataLoading = () =>
    exportOptions.state === RECOIL_LOADABLE_LOADING_STATE;

  const handleFunc = async (func, identifier, data) => {
    setIsLoading(true);
    try {
      await func(identifier, data);
    } catch (error) {
      showError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getExportIdentifier = (useSourceJobId, useRecordId) => {
    if (useSourceJobId) {
      return sourceJobId;
    }
    if (useRecordId) {
      return recordId;
    }
    return _id;
  };
  const getButtonAction = useCallback(() => {
    if (!exportOptions?.contents?.editorEnabled) {
      return handleOpenReviewDialog;
    }
    return handleRedirectToEditor;
  }, [
    handleRedirectToEditor,
    handleOpenReviewDialog,
    exportOptions?.contents?.editorEnabled,
  ]);

  const mapDropDownOptions = useCallback(
    (options) =>
      options?.map((exportFunc, index) => {
        const { func, buttonKey, useRecordId, useSourceJobId } = exportFunc;
        const identifier = getExportIdentifier(useSourceJobId, useRecordId);
        if (!func) {
          return null;
        }
        return (
          <AmberTextButton
            fullWidth
            disableRipple
            classes={{
              root: classes.exportTextButton,
            }}
            onClick={() => handleFunc(func, identifier, { targetJobId: _id })}
            text={t(`common.button.${buttonKey}`)}
            key={generateKey(index)}
          />
        );
      }),
    [handleFunc, getExportIdentifier],
  );

  return (
    <Job
      job={job}
      key={recordId}
      buttonText={getButtonActionTranslation(
        exportOptions?.contents?.editorEnabled,
        t,
      )}
      handleOnClickAction={getButtonAction()}
      disabled={false}
      hasDetailsAction={false}
      handleOnClickReport={handleOnClickReport}
      customExportOptions={
        isDataLoading() || hasError(exportOptions)
          ? []
          : mapDropDownOptions(exportOptions.contents.exportOptions)
      }
    />
  );
}

JobWrapper.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string,
    recordId: PropTypes.string,
    sourceJobId: PropTypes.string,
    workflowStyle: PropTypes.string,
    transcriptionType: PropTypes.string,
  }).isRequired,
  handleOnClickReport: PropTypes.func.isRequired,
  handleRedirectToEditor: PropTypes.func.isRequired,
  handleOpenReviewDialog: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
Job.defaultProps = {
  hasDetailsAction: true,
};

export default withQualityControlLoading(JobWrapper);
