import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import SelectAutosuggest from '../common/fields/selectautosuggest';
import { useTranslation } from 'react-i18next';

export default function InviteDialog(props) {
  const { open, handleInvite, transcribersOptions, selectedjobIds } = props;
  const [selectedTranscribers, setSelectedTranscribers] = React.useState(null);
  const [t, i18n] = useTranslation();

  function inviteHandler() {
    handleInvite(
      selectedTranscribers.map((t) => t.value),
      selectedjobIds,
    );
    handleClose();
  }

  function handleSelectTranscriber(value) {
    setSelectedTranscribers(value);
  }

  function handleClose() {
    props.handleClose();
    setSelectedTranscribers(null);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-dialog-title"
      fullWidth={true}
    >
      <DialogTitle id="invite-dialog-title">Invite transcribers</DialogTitle>
      <DialogContent>
        <SelectAutosuggest
          isMulti={true}
          label="Transcribers"
          value={selectedTranscribers}
          onChange={handleSelectTranscriber}
          placeholder="Select transcribers"
          suggestions={transcribersOptions.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('common.button.close')}
        </Button>
        <Button
          onClick={inviteHandler}
          disabled={
            selectedTranscribers === null || selectedTranscribers.length === 0
          }
          color="primary"
        >
          {t('common.button.invite')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

InviteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleInvite: PropTypes.func.isRequired,
  transcribersOptions: PropTypes.arrayOf(PropTypes.string),
  selectedjobIds: PropTypes.array.isRequired,
};
