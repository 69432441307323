import { atom } from 'recoil';
import { CURRENT_FILTER_STATE } from './constants';

export const MAX_AUDIO_DURATION = 720;

const acceptedFilters = {
  displayFileName: { operator: 'eq', value: '' },
  language: { operator: 'in', values: [] },
  minAudioDuration: { operator: 'gte', value: 0 },
  maxAudioDuration: { operator: 'lte', value: MAX_AUDIO_DURATION * 60 },
  deadline: { operator: 'lte', value: '' },
  transcriber: { operator: 'in', values: [] },
  perfectJobManager: { operator: 'eq', value: '' },
  qualityController: { operator: 'in', values: [] },
  _id: { operator: 'eq', value: '' },
  client: { operator: 'eq', value: '' },
  clientExactMatch: { operator: null, value: true },
  status: { operator: 'in', values: '' },
  transcriptionType: { operator: 'eq', value: '' },
  workflowStyle: { operator: 'eq', value: '' },
  workflowStatus: { operator: 'eq', value: '' },
  notificationCounterForAssignedTranscriber: { operator: 'lte', value: '' },
  externalCustomerIdentifier: { operator: 'eq', value: '' },
  externalJobIdentifier: { operator: 'eq', value: '' },
  jobSource: { operator: 'in', values: '' },
};

export const currentFiltersState = atom({
  key: CURRENT_FILTER_STATE,
  default: acceptedFilters,
});
